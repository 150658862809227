import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static consumer = createConsumer()

  connect() {
    const userIdElement = document.getElementById("user_id_element")
    const userId = userIdElement ? userIdElement.value || userIdElement.innerText : null

    this.terminalChannel = this.constructor.consumer.subscriptions.create({ channel: "RobotLogChannel", user_id: userId }, {
      connected: this.handleConnected.bind(this),
      disconnected: this.handleDisconnected.bind(this),
      received: this.handleReceived.bind(this)
    })

    this.terminalElement = document.getElementById("robot_log")
  }

  handleConnected() {
    // Auto scroll to the bottom
    this.terminalElement.scrollTop = this.terminalElement.scrollHeight
  }

  handleDisconnected() {
    console.log("Disconnected from TerminalChannel")
  }

  handleReceived(data) {
    this.updateTerminalView(data)
  }

  updateTerminalView(data) {
    const newLine = document.createElement('div')
    newLine.innerText = `[${data.created_at}] ${data.message}`
    this.terminalElement.appendChild(newLine)

    // Auto scroll to the bottom
    this.terminalElement.scrollTop = this.terminalElement.scrollHeight
  }
}
