
import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static consumer = createConsumer()

  connect() {
    const rateIdElement = document.getElementById("rate_id_element")
    const currency = rateIdElement ? rateIdElement.value || rateIdElement.innerText : null

    this.rateChannel = this.constructor.consumer.subscriptions.create({ channel: "RateChannel", currency: currency }, {
      connected: this.handleConnected.bind(this),
      disconnected: this.handleDisconnected.bind(this),
      received: this.handleReceived.bind(this)
    })

    this.macdElement = document.getElementById("last_value_macd")
    this.percentileElement = document.getElementById("last_value_percentile")
  }

  handleConnected() {
    console.log("Connent from RateChannel")
  }

  handleDisconnected() {
    console.log("Disconnected from RateChannel")
  }

  handleReceived(data) {
    this.updateView(data)
  }

  updateView(data) {
    this.updateElementWithHighlight(this.macdElement, data.macd)
    this.updateElementWithHighlight(this.percentileElement, data.percentile)
  }

  updateElementWithHighlight(element, newValue) {
    element.innerText = newValue
    element.classList.add("highlight")

    // 在 1.5 秒後移除 highlight class
    setTimeout(() => {
      element.classList.remove("highlight")
    }, 1500)
  }
}
