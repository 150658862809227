import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'monthly', 'yearly',
    'basicPrice', 'premiumPrice', 'goldPrice',
    'basicPriceNote', 'premiumPriceNote', 'goldPriceNote',
    'currencySelector'  // 新增目標
  ];

  connect() {
    this.currency = 'USDT';  // 初始化貨幣為 USDT
    this.selectMonthly();     // 初始化為年度價格
  }

  selectMonthly() {
    this.monthlyTarget.classList.add('btn-primary');
    this.monthlyTarget.classList.remove('btn-outline-primary');
    this.yearlyTarget.classList.add('btn-outline-primary');
    this.yearlyTarget.classList.remove('btn-primary');

    if (this.currency === 'USDT') {
      this.basicPriceTarget.textContent = "USDT$ 7 / 月";
      this.premiumPriceTarget.textContent = "USDT$ 16 / 月";
      this.goldPriceTarget.textContent = "USDT$ 30 / 月";
      this.basicPriceNoteTarget.textContent = "";
      this.premiumPriceNoteTarget.textContent = "";
      this.goldPriceNoteTarget.textContent = "";
    } else {
      this.basicPriceTarget.textContent = "TWD$ 224 / 月";
      this.premiumPriceTarget.textContent = "TWD$ 512 / 月";
      this.goldPriceTarget.textContent = "TWD$ 960 / 月";
      this.basicPriceNoteTarget.textContent = "";
      this.premiumPriceNoteTarget.textContent = "";
      this.goldPriceNoteTarget.textContent = "";
    }
  }

  selectYearly() {
    this.yearlyTarget.classList.add('btn-primary');
    this.yearlyTarget.classList.remove('btn-outline-primary');
    this.monthlyTarget.classList.add('btn-outline-primary');
    this.monthlyTarget.classList.remove('btn-primary');

    if (this.currency === 'USDT') {
      this.basicPriceTarget.textContent = "USDT$ 80 / 年";
      this.premiumPriceTarget.textContent = "USDT$ 185 / 年";
      this.goldPriceTarget.textContent = "USDT$ 350 / 年";
      this.basicPriceNoteTarget.textContent = "省下 4 USDT";
      this.premiumPriceNoteTarget.textContent = "省下 7 USDT";
      this.goldPriceNoteTarget.textContent = "省下 10 USDT";
    } else {
      this.basicPriceTarget.textContent = "TWD$ 2560 / 年";
      this.premiumPriceTarget.textContent = "TWD$ 5920 / 年";
      this.goldPriceTarget.textContent = "TWD$ 11200 / 年";
      this.basicPriceNoteTarget.textContent = "省下 128 TWD";
      this.premiumPriceNoteTarget.textContent = "省下 224 TWD";
      this.goldPriceNoteTarget.textContent = "省下 320 TWD";
    }
  }

  toggleCurrency(event) {
    console.log("qqq")
    this.currency = event.target.value;  // 取得選擇的貨幣類型
    this.updatePrices();                 // 更新價格顯示
  }

  updatePrices() {
    // 根據目前的選擇（月或年）顯示對應的價格
    if (this.monthlyTarget.classList.contains('btn-primary')) {
      this.selectMonthly();
    } else {
      this.selectYearly();
    }
  }
}
