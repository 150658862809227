// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import 'bootstrap';
import '../stylesheets/application.scss';
import '../vendor/adminlte';

import { Application } from "@hotwired/stimulus"
import RateController from "../controllers/rate_controller"
import RobotLogController from "../controllers/robot_log_controller"
import CurrencySettingsController from "../controllers/currency_settings_controller"
import OrderBooksController from "../controllers/order_books_controller"
import ToggleController from "../controllers/toggle_controller"

import DataTable from 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';

import flatpickr from "flatpickr";
import { Mandarin } from "flatpickr/dist/l10n/zh.js";
import "flatpickr/dist/flatpickr.css"

import ApexCharts from 'apexcharts';

const application = Application.start()

application.register("rate", RateController)
application.register("robot_log", RobotLogController)
application.register("currency-settings", CurrencySettingsController)
application.register("order-books", OrderBooksController)
application.register("pricing-toggle", ToggleController)

document.addEventListener("DOMContentLoaded", () => {
  flatpickr(".datepicker", {
    locale: Mandarin,          // 設定語言為中文
    dateFormat: "Y-m-d",       // 日期格式 (可依需求調整)
    enableTime: false,         // 是否啟用時間選擇
  });

  const reportDataElement = document.querySelector('#report-data');
  if (reportDataElement) {
    const reportData = JSON.parse(reportDataElement.dataset.reportData);
    const changes = reportData.map(item => parseFloat(item[0]).toFixed(2));
    const interestIncome = reportData.map(item => parseFloat(item[2]).toFixed(2));
    const roi = reportData.map(item => parseFloat(item[3]).toFixed(2));
    const dates = reportData.map(item => item[1]); 

    const options = {
      chart: {
        type: 'line',
        height: 350,
      },
      series: [{
        name: '資產變化',
        data: changes,
      },
      {
        name: '利息收入',
        data: interestIncome
      },
      {
        name: '利率',
        data: roi
      }
    ],
      xaxis: {
        categories: dates
      }
    };

    const chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
  }
});


document.addEventListener('DOMContentLoaded', function() {
  const table = document.querySelector('#loan-table');

  if (table) {
    new DataTable(table, {
        paging: false,        // 啟用/禁用分頁
        searching: false,     // 啟用/禁用搜尋欄
        ordering: true,      // 啟用/禁用排序
        info: false,          // 是否顯示表格資訊 (例如顯示目前行數)
        lengthChange: false, // 是否允許用戶改變每頁顯示的行數
        pageLength: 200,       // 每頁顯示幾行
        order: [[0, 'asc']], // 預設的排序欄位 (0 表示第一欄) 和排序方式 ('asc' 或 'desc')
        columnDefs: [
          { targets: 0, orderable: false },
          { targets: 6, orderable: false },
          { targets: 7, visible: false, searchable: false },
          {
            targets: 4,  // "到期日" 欄 (顯示的日期欄)
            orderData: 7 // 指定排序使用隱藏的第8欄
          } 
        ],
        language: {
          search: "搜尋:",    // 修改搜尋框的佈局顯示文字
          lengthMenu: "顯示 _MENU_ 筆資料",
          info: "顯示第 _START_ 至 _END_ 筆資料，總共 _TOTAL_ 筆",
          paginate: {
            first: "首頁",
            last: "末頁",
            next: "下一頁",
            previous: "上一頁"
          }
        }
    });
  }
});