import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="currency-settings"
export default class extends Controller {
  static targets = ["mode", "semiManualPartial", "fullManualPartial"]

  connect() {
    console.log("COOL")
    this.toggleFields()
  }

  toggleFields() {
    const selectedMode = this.modeTarget.value

    if (selectedMode === "default") {
      this.hideFields(["semiManualPartial", "fullManualPartial"])
    } else if (selectedMode === "semi_manual") {
      this.showFields(["semiManualPartial"])
      this.hideFields(["fullManualPartial"])
    } else {
      this.showFields(["semiManualPartial"])
      this.showFields(["fullManualPartial"])
    }
  }

  showFields(fields) {
    fields.forEach(field => {
      this[`${field}Target`].classList.remove("d-none")
    })
  }

  hideFields(fields) {
    fields.forEach(field => {
      this[`${field}Target`].classList.add("d-none")
    })
  }
}
