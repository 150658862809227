
import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
    static targets = ["orderBookBody"]

    static consumer = createConsumer()

    connect() {
        const rateIdElement = document.getElementById("rate_id_element")
        const currency = rateIdElement ? rateIdElement.value || rateIdElement.innerText : null
    
        this.rateChannel = this.constructor.consumer.subscriptions.create({ channel: "OrderBooksChannel", currency: currency }, {
            connected: this.handleConnected.bind(this),
            disconnected: this.handleDisconnected.bind(this),
            received: this.handleReceived.bind(this)
          })
    }
  
  // 处理连接成功
  handleConnected() {
    console.log("Connected to OrderBooksChannel")
  }

  // 处理断开连接
  handleDisconnected() {
    console.log("Disconnected from OrderBooksChannel")
  }

  // 处理接收到的数据并更新表格
  handleReceived(data) {

    // 解析订单簿数据
    const side = data.side
    const rate = data.rate 
    const amount = data.amount
    const period = data.period
    const time = data.time

    const newRow = document.createElement('tr')

    newRow.innerHTML = `<td>${side}</td><td>${rate} <small>%</small></td><td>${amount}</td><td>${period}</td><td>${time}</td>`

    this.orderBookBodyTarget.insertBefore(newRow, this.orderBookBodyTarget.firstChild)

    const maxRows = 12
    while (this.orderBookBodyTarget.rows.length > maxRows) {
      this.orderBookBodyTarget.removeChild(this.orderBookBodyTarget.lastChild)
    }
  }

  disconnect() {
    console.log("Unsubscribing from OrderBooksChannel...")
    if (this.subscription) {
      this.subscription.unsubscribe() // 断开 Action Cable 连接
    }
  }
}
